import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const TylerPerryPage = () => {
    const article = {
        id: '9d5e7dfc-486f-5860-b8fc-7bed0ce15973',
        title: 'How Tyler Perry Went From Homeless To A Net Worth of $400 Million',
        slug: '/tyler-perry/',
        date: '2018-01-11T04:46:48.000Z',
        modified: '2021-10-28T17:19:03.000Z',
        excerpt: 'Tyler Perry grew up poor and lived on the street. Now he&#8217;s a Hollywood success story worth hundreds of millions. His amazing career is full of valuable business and finance lessons.',
        featured_image: {
            source_url: '/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-400-million.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 12,
        content: (
            <>
                <p>In his youth, Tyler Perry was poor, abused, homeless, and suicidal.</p>
                <p>Today, he&rsquo;s worth at least $400 million.</p>
                <p>In 20 years, the actor, writer, and producer went from sleeping in a car to bedding down in a Beverly Hills mansion.</p>
                <p>Few of us have faced the daunting challenges that Perry did. Nor have we gotten so rich.</p>
                <p>How he made the leap from poverty to fortune offers career and financial lessons for anyone.</p>
                <p><LazyLoadImage src="/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-2-1.png" alt="diagram" /></p>
                <p>Perry&rsquo;s life got off to a rocky start. One of four children, he grew up poor in a shotgun house in New Orleans. </p>
                <p>His father beat him, others sexually abused him. </p>
                <p>Perry even attempted suicide as a teenager.</p>
                <p>In 1991, Perry heard Oprah Winfrey on television saying that writing things down can be cathartic. </p>
                <p>Something clicked.</p>
                <p>Perry put pen to paper and filled a journal with letters to himself. They grew into his first play, &quot;I Know I&rsquo;ve Been Changed,&quot; about survivors of child abuse.</p>
                <p>That script was the start of Perry&rsquo;s career, but it took years to come to fruition. When he lost all of his money putting on the show he had to live on the street. The play would go on to help make Perry a millionaire.</p>
                <p>Now, his career spans theater, movies, books, television, and a film studio.</p>
                <p>Perry made it purely through his own passion, discipline, and drive. His story shows that with the right mindset, anyone can overcome daunting obstacles to find success.</p>
                <p><strong>Here are five financial lessons we can learn from Tyler Perry&rsquo;s story</strong></p>
                <ol className="list">
                    {' '}
                    <li><a href="https://www.debtconsolidation.com/14-invaluable-habits/">Save every penny</a></li>
                    {' '}
                    <li>Believe in yourself, even when you fail</li>
                    {' '}
                    <li>Focus on just one thing</li>
                    {' '}
                    <li>Know your market</li>
                    {' '}
                    <li><a href="https://www.debtconsolidation.com/budgeting/">Watch your money</a></li>
                </ol>
                <p><LazyLoadImage src="/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-2-2.png" alt="diagram" /></p>
                <h2 id="1-save-every-penny">1. Save every penny</h2>
                <p>By the time Perry was 22, he had set aside $12,000.</p>
                <p>He didn&rsquo;t have a high-paying job or a college degree. Living at home, he worked as a used car salesman, bartender, waiter, and bill collector at a law firm. Anything left over from his paychecks or tax return went into his savings.</p>
                <p>Perry put all of that savings to stage &quot;I Know I&rsquo;ve Been Changed&quot; in Atlanta in 1992. He rented out a 200-seat theater, hired staff, and promoted the play himself. Thirty people showed up.</p>
                <p>Perry&rsquo;s savings were wiped out.</p>
                <p>Undeterred, he took more odd jobs and kept saving money to put on performances over the next several years.</p>
                <p>
                    That time skirting the poverty line&nbsp;
                    <a href="http://www.essence.com/2014/06/13/tyler-perry-spent-financial-documentary-expensive-be-poor">taught Perry an important lesson</a>
                    : &quot;It&rsquo;s expensive to be poor.&quot;
                </p>
                <p>
                    When he was broke, Perry told&nbsp;
                    <em>Essence</em>
&nbsp;magazine, &quot;I cashed all my checks at the quick cash and I was always upset about how much it cost to cash the check.&quot;
                </p>
                <p>
                    Years later, Perry narrated a&nbsp;
                    <a href="http://spentmovie.com/">documentary</a>
&nbsp;called&nbsp;
                    <em>Spent</em>
                    , sponsored by American Express, that follows people in similar situations. Seventy million Americans rely on alternate banking services like check cashing centers and payday lenders. Perry explained the problem to Essence:
                </p>
                <p>&quot;When you&rsquo;re poor, you can&rsquo;t just go to a bank if you don&rsquo;t have the credit to open up a bank account. You have to rely on these services.</p>
                <p>
                    If you go to a check cashing service, you have to pay for that. If you use one of these pre-paid cards, you have to pay for that instead of having a regular
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=tyler-perry&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    .&quot;
                </p>
                <p>There were times when I found myself using such services while working my way through college. I just couldn&rsquo;t make it to the bank to cash my checks before the bank closed. In one month, I spent over $100 on fees just to cash my paychecks. I realized something had to change, or I was going to have to work at least 10 hours a month just to be able to afford to get paid!</p>
                <p><strong>Being poor is expensive, says Perry. Avoid getting into debt, and start saving and building an emergency fund.</strong></p>
                <p>
                    The website for the documentary&nbsp;
                    <em>Spent</em>
&nbsp;includes a financial readiness plan by Manisha Thakor, founder of MoneyZen.&nbsp;
                    <a href="http://spentmovie.com/downloads/Spent_Get_Financially_Ready.pdf">It breaks down how to start saving</a>
                    :
                </p>
                <p>
                    <strong>
                        There is no amount too small to begin your
                        {' '}
                        <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=tyler-perry&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                        .
                    </strong>
&nbsp;One dollar a day will add up to $365 a year.&nbsp;
                    <a href="https://www.creditloan.com/blog/emergency-fund-tips/">Start by building a $200 emergency fund</a>
                    <strong>.&nbsp;</strong>
                    Then aim for at least $2,000, which the&nbsp;
                    <a href="http://consumerfed.org/press_release/national-survey-receals-emergency-savings-needs-and-effective-saver-strategies/">Consumer Federation of America</a>
&nbsp;has found to be the typical amount for unexpected expenses.
                </p>
                <p><LazyLoadImage src="/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-2-3.png" alt="diagram" /></p>
                <p>
                    <strong>Stop spending money on things you don&rsquo;t need.&nbsp;</strong>
                    This is especially true if you have to dip into your savings. Cut out the Starbucks in the morning and brew yourself. That attractive neck pillow in the airplane gift shop — hold your horses.
                </p>
                <p>I always consider whether something is truly a &quot;want&quot; versus a &quot;need&quot; before I make a purchase. Right now my focus is on building my next business, and that means having the cash to do what I need to when I need to, without worrying about our monthly financial obligations.</p>
                <p>
                    <strong>Explore&nbsp;</strong>
                    <a href="http://www.savetowin.org/product-info/how-save-to-win-works">prize-linked savings programs</a>
                    <strong>&nbsp;in your area.</strong>
&nbsp;You can win cash prizes simply for saving money each month. Don&rsquo;t see one listed? Check out&nbsp;
                    <a href="https://www.unitedway.org/find-your-united-way/">your local United Way</a>
                    .
                </p>
                <p>
                    Whether you have creative or entrepreneurial ambitions, or if you just want to feel secure, you need
                    {' '}
                    <a href="https://www.debtconsolidation.com/stick-to-your-budget/">financial discipline</a>
                    {' '}
                    to get there. It will help you pay for an emergency,&nbsp;get you out of debt, and prepare for the future.
                </p>
                <p>Tyler Perry could not have put on his first show without saving money. Without his first show, he might still be waiting tables.</p>
                <h2 id="2-believe-in-yourself-even-when-you-fail">2. Believe in yourself, even when you fail</h2>
                <p>
                    &quot;Do you know&nbsp;
                    <a href="https://www.facebook.com/TylerPerry/posts/10153050579488268">how many times I tried to be successful at doing plays</a>
&nbsp;before it finally worked?&quot; Perry asked on his Facebook page in 2014.
                </p>
                <p>&quot;From 1992 until 1998, every show I put on flopped. No one showed up, and I lost all my money. I wanted to give up.</p>
                <p>I thought I had failed, but the truth is, I never failed. Each and every time the show didn&rsquo;t work, I learned something new. I learned what not to do and what I could do better.</p>
                <p>You have to understand that what you may perceive to be a failure may very well be an opportunity to learn, grow, get better, and prepare for the next level.&quot;</p>
                <p>
                    Many people would take multiple,
                    {' '}
                    <a href="https://www.debtconsolidation.com/bankruptcy/">bankrupting</a>
                    {' '}
                    failures as a cosmic hint. Not Perry.
                </p>
                <p>Instead, he kept at it. When he couldn&rsquo;t pay rent, he lived on the streets. In late 1996, he spent three months sleeping in his car or homeless shelters.</p>
                <p>Perry&rsquo;s mother begged him to get a steady job. But he refused to give up on his writing.</p>
                <p>
                    &quot;I would not stop believing,&quot; Perry&nbsp;
                    <a href="https://www.youtube.com/watch?v=FFimDpjZhhY">says in a YouTube video</a>
                    .
                </p>
                <p>For entrepreneurs in particular, this self-confidence is crucial. Steve Jobs and Elon Musk are other examples of people who found success even after multiple failures.</p>
                <p>We&rsquo;ve all been told to believe in ourselves but that&rsquo;s hard in real life. For many of us, it requires a total shift in thinking.</p>
                <p>
                    Yaro Starak, an entrepreneur and blogger, has written about&nbsp;
                    <a href="https://www.entrepreneurs-journey.com/11020/inevitability-thinking/">what steps you can take to get into a productive mindset</a>
                    .
                </p>
                <p>When bad things happen, don&rsquo;t view them as permanent or reflective of a character flaw, he explains. Instead, consider them stepping stones towards positive outcomes.</p>
                <p>&quot;See failures as temporary and an indication of a technique that didn&rsquo;t work rather than a permanent flaw in you as a person. Know that as long as you keep working, things improve.&quot;</p>
                <p>He credits business coach Eben Pagan with the concept of &quot;inevitability thinking,&quot; which means acting like your goal is going to happen as long as you set up the right conditions.</p>
                <p>&quot;Inevitability thinking is about having the confidence to know that a given outcome is available to you if you do certain things. This is an absolute fact. It has to happen eventually…Once you know the conditions required your only job is to make them a reality.&quot;</p>
                <p>Perry staged his play for six years before he succeeded. That&rsquo;s a long time to believe in yourself, but it worked.</p>
                <p>In the summer of 1998, he once again put his savings toward funding a production of &quot;I Know I&rsquo;ve Been Changed.&quot; Only this time, things went differently.</p>
                <p>Perry tirelessly promoted the show to black churches around Atlanta. Its run at the House of Blues sold out, as did subsequent shows at the Fox Theater. From there, he was off, touring his plays across the country for eight years.</p>
                <h2 id="3-focus-on-just-one-thing">3. Focus on just one thing</h2>
                <p>When asked what it takes to be successful, Perry says it comes down to focus and hard work.</p>
                <p>Many people are overambitious, dabbling in many fields, instead of pouring all of their effort into one thing.</p>
                <p>&quot;Plant your seed in the ground, water it, and believe,&quot; he says in the YouTube video. &quot;I had one idea, that was to do a play…I knew if I could get that to work everything else would come to pass.&quot;</p>
                <p>For six years, he remained committed to his play. Even when people repeatedly said no and nobody came to performances.</p>
                <p>&quot;Put all your energy into watering one area. If you spread the water across many seeds you don&rsquo;t have as much water for one seed. So focus on one thing. Make it your priority and stick with it no matter what.&quot;</p>
                <p>
                    Author Tom Corley, who blogs at Rich Habits, has studied the wealthy for years. He&rsquo;s found that at the core of many self-made millionaires like Perry is&nbsp;
                    <a href="http://richhabits.net/hardwired-focus-one-thing-time/">passion and single-minded focus</a>
                    :
                </p>
                <p>&quot;They devote [many] years to learning, growing, and practicing until they become expert in whatever it is they are pursuing. They avoid any distractions that prevent them from their single-minded pursuit.</p>
                <p>Eventually, they become expert in what they do. Only after they become an expert do they then begin to realize financial success. And this, I found, takes a long time.&quot;</p>
                <p>This is how our brains are wired, Corley says.</p>
                <p>&quot;When you pursue something you are passionate about, your brain enters something called flow. Flow is a mental state that marshals all of the resources of the brain…</p>
                <p>When your brain is in a state of flow, you are able to see solutions to the problems and obstacles that get in your way. Flow can only be achieved through singular focus.&quot;</p>
                <p>For those of us not writing our breakout play, this means throwing all of our intellectual resources into our chosen field.</p>
                <p>Take extra classes, read industry news and books, or study for a certification. As an expert, you&rsquo;re more likely to find financial and personal success.</p>
                <p>Perry hammers this message home: &quot;Don&rsquo;t stop. Narrow your focus to one idea. One. And make it work. That will give birth to all the others.&quot;</p>
                <p>It did. After the success of his play, Perry went on to Hollywood.</p>
                <p>
                    He has written, starred, or directed in dozens of movies and TV shows, like the&nbsp;
                    <em>Madea</em>
&nbsp;films and the TBS series&nbsp;
                    <em>House of Payne</em>
                    . He also has written a bestselling book and built a sprawling production studio in Atlanta.
                </p>
                <p>
                    {' '}
                    <LazyLoadImage src="/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-2-4.png" alt="diagram" />
                </p>
                <h2 id="4-know-your-market">4. Know your market</h2>
                <p>
                    In 2005, Perry released the movie&nbsp;
                    <em>Diary of a Mad Black Woman</em>
                    , which he wrote, produced, and starred in.
                </p>
                <p>Perry paid half of the $5 million production costs with his own money.</p>
                <p>Critics panned the movie, about a gun-toting woman, played by Perry, seeking to avenge her jilted granddaughter.</p>
                <p>
                    &quot;I&rsquo;ve been reviewing movies for a long time, and I can&rsquo;t think of one that more dramatically shoots itself in the foot,&quot; wrote Roger Ebert of Perry. &quot;What was he&nbsp;
                    <em>thinking</em>
                    ?&quot;
                </p>
                <p>Perry&rsquo;s fans thought otherwise.</p>
                <p>On opening weekend, the movie was number one at the box office, earning $21 million. Domestic grosses totaled $50 million, ten times what it cost to make.</p>
                <p>
                    &quot;I know my audience,&quot; Perry said in a 2017 interview with&nbsp;
                    <em>The Hollywood Reporter,&nbsp;</em>
                    <a href="http://www.hollywoodreporter.com/features/tyler-perry-talks-race-hollywood-bill-cosby-creative-freedom-i-dont-get-notes-1003287">which named him TV Producer of the Year</a>
                    .
                </p>
                <p>&quot;I think that if more artists were free to just be raw, some of the stuff that would show up would be phenomenally successful.&quot;</p>
                <p>Anyone launching a venture or trying to break into a new field could learn from this. Knowing your market is crucial to success.</p>
                <p>
                    Writing in the&nbsp;
                    <em>Guardian</em>
                    , James Caan, former chairman of the Start Up Loans Company,&nbsp;
                    <a href="https://www.theguardian.com/small-business-network/2014/jun/11/james-caan-market-research">explains the importance of market research</a>
                    :
                </p>
                <p>&quot;You need to know your market, competitors and potential customers inside out, and thorough research will help you uncover any gaps in the market.</p>
                <p><strong>What Perry practices to stand out in crowded markets</strong></p>
                <p>Remember, you shouldn&rsquo;t feel disheartened if the idea has been attempted before – as long as you have a unique selling point and can add value to the market, you can still stand out from your competitors.</p>
                <ul className="list">
                    {' '}
                    <li>Read up on trends and sales figures in your sector</li>
                    {' '}
                    <li>Study your competition, such as price points and who their customers are</li>
                    {' '}
                    <li>Attend networking events to get tips from people in the field</li>
                    {' '}
                    <li>Talk to people about your business and seek out advice</li>
                </ul>
                <p>For Perry, market research means knowing his audience. It&rsquo;s a segment that Hollywood has long overlooked.</p>
                <p>Having performed in 30,000-person arenas in hundreds of shows across the country, Perry knows what people laugh at or respond to emotionally. He can write characters that feel true because he&rsquo;s met men and women like them.</p>
                <p>
                    When Perry began writing shows for Oprah Winfrey&rsquo;s television network, OWN, Winfrey&nbsp;
                    <a href="http://www.chicagotribune.com/entertainment/tv/ct-tyler-perry-own-20160405-story.html">thought that some scenes went over the top</a>
                    . Perry pushed back. Winfrey told the Chicago Tribune:
                </p>
                <p>&quot;He would say to me, &lsquo;I know this audience better than you do, I know what the audience wants,&rsquo;&quot; [Winfrey] said. &quot;And he&rsquo;d say, &lsquo;I&rsquo;m telling you it&rsquo;s gonna work.&rsquo; And every time he&rsquo;s been right.&quot;</p>
                <p>Perry&rsquo;s shows delivered record-breaking ratings for OWN.</p>
                <h2 id="5-watch-your-money">5. Watch your money</h2>
                <p>Never complacent about his success, Perry remains hands-on with his projects. He maintains creative control over his work, getting the final say about what appears onscreen.</p>
                <p>Perry also keeps a close eye on the bottom line.</p>
                <p>
                    In&nbsp;
                    <em>The Hollywood Reporter</em>
&nbsp;interview, Perry said that Winfrey taught him an important lesson: &quot;Sign your own checks. Watch your money.&quot;
                </p>
                <p>Unlike many celebrities, Perry doesn&rsquo;t outsource his accounting. &quot;Every month on the 1st and the 15th, I&rsquo;m sitting right there at that desk with a box full of checks. And at this point, it&rsquo;s probably about 2,000 checks.</p>
                <p>Some people, they&rsquo;re in show business, but they&rsquo;re all about the show and nobody&rsquo;s paying attention to the business.&quot;</p>
                <p>Most of us have fewer checks to sign than Perry, so this should be easy. Apparently not.</p>
                <p><strong>Budgeting may not make you rich, but not managing your spending will keep you poor</strong></p>
                <p>
                    <a href="https://nfcc.org/wp-content/uploads/2017/03/NFCC_BECU_2017-FLS_datasheet-with-key-findings.pdf?_ga=2.202713047.734360750.1493845051-1403476521.1491225914">Sixty percent of Americans don&rsquo;t track their spending or keep a budget</a>
                    , according to the 2017 Consumer Financial Literacy Survey, published by the National Foundation for Credit Counseling.&nbsp;
                    <a href="https://www.debtconsolidation.com/">Many are badly in debt</a>
                    .
                </p>
                <p><LazyLoadImage src="/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-2-5.png" alt="diagram" /></p>
                <p>Budgeting seems complicated, but it doesn&rsquo;t have to be. Digital tools and apps will run the numbers for you.</p>
                <p>
                    One of the most popular resources is&nbsp;
                    <a href="https://www.mint.com/">Mint</a>
                    , which imports information from your bank and credit cards and figures out how much you should save to meet your financial goals. It also lays bare exactly where your money goes.
                </p>
                <p>Other options to help you manage your money and budget include:</p>
                <p>
                    <a href="https://www.youneedabudget.com/apps/">You Need a Budget (YNAB)</a>
                    {' '}
                    With YNAB, every dollar counts. It allows you to create a monthly budget based on your income, expenses, and savings goals.
                </p>
                <p>
                    <a href="https://www.personalcapital.com/">Personal&nbsp;Capital</a>
                    . With over 1 million users, Personal Capital is the real deal. Use its financial tools to make it easy to build a manageable budget and financial plan.
                </p>
                <p>
                    <a href="http://www.spendee.com/">Spendee</a>
                    <strong>.&nbsp;</strong>
                    If you need help monitoring your spending, Spendee is for you. Track your expenses and categorize where your money goes. Figure out what categories you should spend less on and budget for saving.
                </p>
                <p><LazyLoadImage src="/media/how-tyler-perry-went-from-homeless-to-a-net-worth-of-2-6.png" alt="diagram" /></p>
                <p><strong>The easiest and only budget you&rsquo;ll need</strong></p>
                <p>For beginners, the 50/30/20 method offers a simple budgeting baseline:</p>
                <ul className="list">
                    {' '}
                    <li>50% your income goes to necessities such as rent and utilities.</li>
                    {' '}
                    <li>20% goes into savings.</li>
                    {' '}
                    <li>30% goes to discretionary spending, like clothing and eating out.</li>
                </ul>
                <p>
                    Whatever your preferred method, budgeting will make you conscious of your spending habits and help you&nbsp;
                    <a href="https://www.debtconsolidation.com/debt-management/">never get into debt again</a>
                    .
                </p>
                <p>Perry still spends lavishly—he recently bought a $14.5 million mansion in Beverly Hills—but he watches his money. All $400 million of it.</p>
                <p>
                    As Perry likes to say,&nbsp;
                    <strong>&quot;being poor is expensive.&quot;&nbsp;</strong>
                    To reboot your life and start the process towards creating real wealth, you&rsquo;ll first need to get rid of that pesky debt. Take a look at these articles that explain how you can&nbsp;
                    <a href="https://www.debtconsolidation.com/">consolidate your debt</a>
&nbsp;into something manageable and affordable to pay off quickly.
                </p>
                <p>What do you think of Perry and his story? Let everyone know in the comments below!</p>
            </>)
        ,
    };

    return (
        <Post article={article} />
    );
};

export default TylerPerryPage;
